import React, { useState, Fragment } from "react";
import { useSpring, animated } from "react-spring";
import { NavLink } from "react-router-dom";

import * as classes from "./Navbar.module.css";

import Menu from "../Menu/Menu";

const Navbar = () => {
  const [showMenu, set] = useState(false);

  const toggleMenuHandler = () => set(state => !state);

  const {
    opacity,
    transformTop,
    transformBot,
    position,
    top,
    leftTop,
    leftBot
  } = useSpring({
    opacity: showMenu ? 1 : 0,
    transformTop: `rotate(${showMenu ? "45deg" : "0deg"})`,
    transformBot: `rotate(${showMenu ? "-45deg" : "0deg"})`,
    position: "absolute",
    top: "35%",
    leftTop: "10%",
    leftBot: "7%"
  });
  return (
    <Fragment>
      <div className={classes.Wrapper}>
        <nav className={classes.Navbar}>
          <div className={classes.Menu} onClick={toggleMenuHandler}>
            <animated.div
              style={{
                opacity,
                transform: transformTop,
                position,
                top,
                left: leftTop
              }}
            >
              <div className={[classes.Btn, classes.BtnTop].join(" ")}></div>
            </animated.div>
            <animated.div
              style={{
                opacity,
                transform: transformBot,
                position,
                top,
                left: leftBot
              }}
            >
              <div className={[classes.Btn, classes.BtnBot].join(" ")}></div>
            </animated.div>
            <animated.div style={{ opacity: opacity.interpolate(o => 1 - o) }}>
              <div className={classes.Btn}></div>
              <div className={classes.Btn}></div>
              <div className={classes.Btn}></div>
            </animated.div>
          </div>
          <NavLink to="/" className={classes.Logo}>
            <h1>Second Winds Music</h1>
          </NavLink>
        </nav>
      </div>
      <Menu open={showMenu} toggleMenu={toggleMenuHandler} />
    </Fragment>
  );
};

export default Navbar;
