import React from "react";
import * as classes from "./Prep.module.css";
import stretch from "../../assets/img/stretch.jpg";
import asembly from "../../assets/img/asembly.jpg";
import flamesax from "../../assets/img/flamesax.jpg";
import hygiene from "../../assets/img/hygiene.jpg";

import Card from "../../UI/Card/Card";

// react-animate-on-scroll
import ScrollAnimation from "react-animate-on-scroll";

const Prep = () => {
  return (
    <ScrollAnimation animateIn="fadeIn" duration={2}>
      <div className={classes.Wrapper}>
        <p className={classes.Title}>Lesson and Practice Preparation</p>
        <div className={classes.Spacer} />
        <div className={classes.Prep}>
          <ScrollAnimation
            animateIn="fadeInUp"
            duration={1.2}
            animateOnce={true}
          >
            <Card title="Physical" img={stretch} animated>
              Stretch back, arms, fingers
              <br />
              <br /> *simple stretching exercises will be taught to the student
            </Card>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="fadeInUp"
            duration={1.2}
            delay={200}
            animateOnce={true}
          >
            <Card title="Instrument Assembly" img={asembly} animated>
              Proper adjustment of the saxophone body, neck and mouthpiece{" "}
              <br />
              <br />
              Proper placement and securing of the reed
            </Card>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="fadeInUp"
            duration={1.2}
            delay={500}
            animateOnce={true}
          >
            <Card title="Musical Warm Up" img={flamesax} animated>
              Major scales, ascending and descending Chromatic scale, ascending
              and descending <br />
              <br />
              *As the student progresses, minor scales and arpeggios will be
              added
              <br />
              **Warm ups will played at progressing tempos utilizing a metronome
            </Card>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="fadeInUp"
            duration={1.2}
            delay={700}
            animateOnce={true}
          >
            <Card title="Hygiene" img={hygiene} animated>
              Brush teeth and/or rinse mouth
              <br /> Wash hands
              <br />
              <br />
              Clean and sanitize your instrument after each use
            </Card>
          </ScrollAnimation>
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default Prep;
