import React from "react";
import * as classes from "./Contact.module.css";

// react-animate-on-scroll
import ScrollAnimation from "react-animate-on-scroll";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";

const Contact = () => {
  return (
    <ScrollAnimation animateIn="fadeIn">
      <div className={classes.Wrapper}>
        <div className={classes.Background} />
        <div className={classes.Contact}>
          <h4>Contact me to schedule a free lesson</h4>
          <form
            className={classes.Form}
            action="https://formspree.io/secondwindsmusic@gmail.com"
            method="POST"
          >
            <input
              className={classes.Input}
              placeholder="Name"
              type="text"
              name="name"
            />
            <input
              className={classes.Input}
              type="text"
              name="email"
              placeholder="Email"
            />
            <textarea
              className={classes.Textarea}
              name="message"
              cols="30"
              rows="3"
              placeholder="Message"
            />
            <button className={classes.Button} type="submit">
              <FontAwesomeIcon icon={faPaperPlane} /> SEND
            </button>
          </form>
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default Contact;
