import React, { Fragment } from "react";
import * as classes from "./Footer.module.css";
import Logo from "../../assets/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Social from "../../UI/Social/Social";
import {
  faEnvelope,
  faBuilding,
  faPhoneVolume
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <Fragment>
      <div className={classes.Footer}>
        <div className={classes.Main}>
          <div className={classes.Contact}>
            <h2>
              Richard Avila <span>Saxophone Instructor</span>
            </h2>
            <p>
              <FontAwesomeIcon
                className={classes.ContactIcon}
                icon={faEnvelope}
              />{" "}
              secondwindsmusic@gmail.com
            </p>
            <p>
              <FontAwesomeIcon
                className={classes.ContactIcon}
                icon={faPhoneVolume}
              />{" "}
              408.718.0623
            </p>
            <div className={classes.Address}>
              <FontAwesomeIcon
                className={classes.ContactIcon}
                icon={faBuilding}
              />
              <div>
                <p>230 Florence St.</p>
                <p>Sunnyvale, CA 94086</p>
                <p>USA</p>
              </div>
            </div>
          </div>
          <div className={classes.Logo}>
            <img
              className={classes.Image}
              src={Logo}
              alt="Second Winds Music"
            />
            <h1>Second Winds Music</h1>
          </div>

          <Social />
        </div>
      </div>
      <div className={classes.Bot}>
        <p>
          Created my free logo at{" "}
          <a href="https://www.logomakr.com">LogoMakr.com</a>
        </p>
        <p>
          <a href="https://www.jaredavila.com/">Jared Avila Designs</a> |
          Copyright {new Date().getFullYear()}
        </p>
      </div>
    </Fragment>
  );
};

export default Footer;
