import React, { useState } from "react";
import * as classes from "./GreatResources.module.css";

import aAndGMusic from "../../assets/img/aandgmusic.png";
import wVMusic from "../../assets/img/wvm.jpg";
import mathMusic from "../../assets/img/music_instructor_saxophone_private_lessons.jpeg";

import Card from "../../UI/Card/Card";
// react-animate-on-scroll
import ScrollAnimation from "react-animate-on-scroll";

const GreatResources = () => {
  const [resources] = useState([
    {
      title: "Best Instrument Repair",
      img: aAndGMusic,
      content:
        "Best Instrument Repair Co. is a full service instrument repair shop. The largest on the west coast, we service all instruments. For questions about repairs please call us 510.832.2452",
      link: "http://www.agmusic.com/html/repairs.html",
      delay: 0
    },
    {
      title: "West Valley Music",
      img: wVMusic,
      content:
        "Mountain View's #1 destination for instrument rentals, school music books, and all of your back-to-school needs!",
      link: "http://www.westvalleymusic.com/",
      delay: 500
    },
    {
      title: "Music + Math (Article)",
      img: mathMusic,
      content:
        "The Correlation Between Music and Math: A Neurobiology Perspective  by Cindy Zhan",
      link:
        "https://serendipstudio.org/exchange/serendipupdate/correlation-between-music-and-math-neurobiology-perspective",
      delay: 800
    }
  ]);
  return (
    <ScrollAnimation animateIn="fadeIn">
      <div className={classes.Wrapper}>
        <h2>Great Resources</h2>
        <div className={classes.Spacer} />
        <div className={classes.GreatResources}>
          {resources.map(rec => {
            return (
              <ScrollAnimation
                animateIn="flipInY"
                delay={rec.delay}
                key={rec.title}
              >
                <Card
                  link={rec.link}
                  linkTitle="View Site"
                  img={rec.img}
                  title={rec.title}
                >
                  {rec.content}
                </Card>
              </ScrollAnimation>
            );
          })}
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default GreatResources;
