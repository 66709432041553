import React from "react";
import * as classes from "./Button.module.css";
import { NavLink } from "react-router-dom";

const Button = props => {
  const solidBtnClass = [classes.Btn, classes.Solid];
  const outlineBtnClass = [classes.Btn, classes.Outline];
  const linkBtnClass = [classes.Btn, classes.Link];
  if (props.linkBtn)
    return (
      <NavLink to={props.link}>
        <button className={linkBtnClass.join(" ")}>{props.children}</button>
      </NavLink>
    );
  return props.outline ? (
    <NavLink to={props.link}>
      <button className={outlineBtnClass.join(" ")}>{props.children}</button>
    </NavLink>
  ) : (
    <NavLink to={props.link}>
      <button className={solidBtnClass.join(" ")}>{props.children}</button>
    </NavLink>
  );
};

export default Button;
