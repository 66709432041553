import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useLax } from "use-lax";

// components
import Navbar from "./components/Navbar/Navbar";
import Home from "./containers/Home/Home";
import AboutContainer from "./containers/Home/AboutContainer";
import NotFound from "./components/NotFound/NotFound";
import ScrollToTop from "./hoc/ScrollToTop";
import Contact from "./components/Contact/Contact";
import GreatResources from "./components/GreatResources/GreatResources";
import Footer from "./components/Footer/Footer";
import FootMenu from "./components/FootMenu/FootMenu";
import SolidFoundation from "./components/SolidFoundation/SolidFoundation";

// react-animate-on-scroll
import ScrollAnimation from "react-animate-on-scroll";

const App = () => {
  useLax();
  return (
    <Router>
      <ScrollToTop />
      <ScrollAnimation animateIn="fadeIn" duration={1.5} animateOnce={true}>
        <div className="App">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" component={AboutContainer} />
            <Route path="/solid_foundation" component={SolidFoundation} />
            <Route path="/great_resources" component={GreatResources} />
            <Route path="/contact" component={Contact} />
            <Route path="*" component={NotFound} />
          </Switch>

          <Navbar />
          <Footer />
          <FootMenu />
        </div>
      </ScrollAnimation>
    </Router>
  );
};

export default App;
