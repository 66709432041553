import React from "react";
import * as classes from "./Landing.module.css";

// react-animate-on-scroll
import ScrollAnimation from "react-animate-on-scroll";

// lax.js scrolling animations
import { useLaxElement } from "use-lax";

import Button from "../../UI/Button/Button";

const Landing = () => {
  const ref = useLaxElement();
  const landingClasses = [classes.Landing, "lax"];
  const headlineClasses = [classes.Headline, "lax"];
  return (
    <div
      className={landingClasses.join(" ")}
      ref={ref}
      data-lax-opacity="0 1, 300 0.4"
    >
      <div
        className={headlineClasses.join(" ")}
        ref={ref}
        data-lax-translate-y="0 0, 600 600"
        data-lax-opacity="0 1, 500 0"
      >
        <ScrollAnimation animateIn="fadeInDown" duration={2} animateOnce={true}>
          <h1>Serving the Silicon Valley</h1>
          <h4>
            The goal of my teaching is to impart to my students an appreciation,
            understanding and a love for music
          </h4>
          <div className={classes.Btn}>
            <Button link="/contact">Book FREE Lesson</Button>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Landing;
