import React from "react";
import * as classes from "./About.module.css";
import pic from "../../assets/img/richard_avila_saxophone_instructor.jpg";

// react-animate-on-scroll
import ScrollAnimation from "react-animate-on-scroll";

import Social from "../../UI/Social/Social";

const About = () => {
  return (
    <div className={classes.About}>
      <div className={classes.Wrapper}>
        <ScrollAnimation
          animateIn="fadeInLeft"
          animateOnce={true}
          duration={1.5}
        >
          <img src={pic} alt="Richard Avila | Saxophone Instructor" />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInRight"
          animateOnce={true}
          duration={1.5}
        >
          <div className={classes.Info}>
            <h2>Richard Avila</h2>
            <h1>Saxophone instructor</h1>
            <div className={classes.Bio}>
              <div className={classes.Exp}>
                <h4>Background/Experience</h4>
                <p>
                  I began playing the saxophone in 8th grade. In high school I
                  was 1st chair alto in Symphonic Band and Jazz Ensemble. While
                  still in school, formed and played in a jazz quartet that won
                  the Unanimous Superior award at the California State High
                  School Music Competition. After high school, I played Lead
                  Alto and 1st Tenor saxophone in the West Valley College Jazz
                  Ensemble over a 4 year period. Also played flute, alto and
                  soprano saxophones with the jazz, latin fusion band Zamar for
                  twelve years (comprised of 7 professional level musicians).
                  Also was the music leader of the music departments for 3
                  different churches in the Santa Clara Valley over a 12 year
                  period.
                </p>
              </div>
              <div className={classes.Awards}>
                <h4>Awards/Prestige</h4>
                <ul>
                  <li>1st Alto Saxophone, Santa Clara County Honor Band</li>
                  <li>2nd Alto, South Bay Honor Jazz Ensemble</li>
                  <li>Music Department Achievement Award</li>
                  <li>John Philips Souza Band Award</li>
                  <li>1st Tenor, San Jose State University Wind Ensemble</li>
                </ul>
              </div>
            </div>
            <Social />
          </div>
        </ScrollAnimation>

        <div className={classes.Background} />
      </div>
    </div>
  );
};

export default About;
