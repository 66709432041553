import React from "react";
import { useSpring, animated } from "react-spring";
import * as classes from "./Menu.module.css";
import { NavLink } from "react-router-dom";

import Button from "../../UI/Button/Button";
import Social from "../../UI/Social/Social";

const Menu = props => {
  const { opacity, display } = useSpring({
    opacity: props.open ? 0.94 : 0.5,
    display: props.open ? "block" : "none"
  });

  return (
    <animated.div
      style={{
        opacity,
        display
      }}
    >
      <div className={classes.Menu}>
        <ul className={classes.List} onClick={props.toggleMenu}>
          <li className={classes.ListItem}>
            <p className={classes.Number}>01</p>
            <NavLink className={classes.Item} to="/">
              Home
            </NavLink>
          </li>
          <li className={classes.ListItem}>
            <p className={classes.Number}>02</p>
            <NavLink className={classes.Item} to="/about">
              About
            </NavLink>
          </li>

          <li className={classes.ListItem}>
            <p className={classes.Number}>03</p>
            <NavLink className={classes.Item} to="/solid_foundation">
              Solid Foundation
            </NavLink>
          </li>
          <li className={classes.ListItem}>
            <p className={classes.Number}>04</p>
            <NavLink className={classes.Item} to="/great_resources">
              Great Resources
            </NavLink>
          </li>
          <li className={[classes.ListItem, classes.Button].join(" ")}>
            <Button outline link="/contact">
              Schedule FREE Lesson
            </Button>
          </li>
          <li className={classes.ListItem}>
            <div className={classes.Social}>
              <Social gold />
            </div>
          </li>
        </ul>
      </div>
    </animated.div>
  );
};

export default Menu;
