import React from "react";
import { useLocation } from "react-router-dom";
import * as classes from "./NotFound.module.css";

const NotFound = () => {
  let location = useLocation();
  return (
    <div className={classes.NotFound}>
      <h3>
        <span>Error 404:</span> No match for <br />
        <code className={classes.Url}>
          "www.secondwindsmusic{location.pathname}"
        </code>
      </h3>
    </div>
  );
};

export default NotFound;
