import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import * as classes from "./Social.module.css"

const Social = props => {
    let IconClasses = [classes.Icon, classes.IconRed];
    if(props.gold){
        IconClasses = [classes.Icon, classes.IconGold];
    }
    return (
        <div className={classes.Social}>
            <a
              href="https://www.facebook.com/secondwindsmucis/"
              className={IconClasses.join(" ")}
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href="https://www.linkedin.com/in/richard-avila-72847b3b/"
              className={IconClasses.join(" ")}
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
    )
}

export default Social
