import React from "react";
import * as classes from "./FootMenu.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { NavLink } from "react-router-dom";

const FootMenu = () => {
  return (
    <div className={classes.FootMenu}>
      <NavLink className={classes.Btn} to="/">
        <FontAwesomeIcon className={classes.Icon} icon={faHome} />
        <p>Home</p>
      </NavLink>
      <NavLink className={classes.Btn} to="/about">
        <FontAwesomeIcon className={classes.Icon} icon={faChalkboardTeacher} />
        <p>About Instructor</p>
      </NavLink>
      <NavLink className={classes.Btn} to="/contact">
        <FontAwesomeIcon className={classes.Icon} icon={faCalendarAlt} />
        <p>Book FREE Lesson</p>
      </NavLink>
    </div>
  );
};

export default FootMenu;
