import React from "react";
import * as classes from "./Lessons.module.css";

import Button from "../../UI/Button/Button";

// react-animate-on-scroll
import ScrollAnimation from "react-animate-on-scroll";

const Lessons = () => {
  return (
    <div className={classes.Lessons}>
      <ScrollAnimation animateIn="fadeIn" duration={2}>
        <div className={classes.Wrapper}>
          <ScrollAnimation
            animateIn="fadeInLeft"
            duration={1}
            animateOnce={true}
            className={classes.TaglineWrapper}
          >
            <h2 className={classes.Tagline}>Saxophone Lessons</h2>
            <Button className={classes.Btn} outline link="/contact">
              Schedule A Lesson
            </Button>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="fadeInRight"
            animateOnce={true}
            className={classes.TextBoxWrapper}
          >
            <div className={classes.TextBox}>
              <p className={classes.Text}>
                I provide beginning and intermediate (1 year to 5 years playing
                experience or equivalent playing ability) level lessons on
                soprano, alto, tenor and baritone saxophones.
              </p>
              <p className={classes.Text}>
                Each student will have their own individual lesson plan and
                progress is recorded and evaluated on an ongoing basis.
              </p>
            </div>
          </ScrollAnimation>
        </div>
      </ScrollAnimation>
    </div>
  );
};

export default Lessons;
