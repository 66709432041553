import React from "react";
import * as classes from "./Home.module.css";

// components
import Landing from "../../components/Landing/Landing";
import Lessons from "../../components/Lessons/Lessons";
import Prep from "../../components/Prep/Prep";
import Bio from "../../components/Bio/Bio";
import Testimonials from "../../components/Testimonials/Testimonials";

const Home = () => {
  return (
    <div className={classes.Home} id="home">
      <Landing />
      <Lessons />
      <Bio />
      <Testimonials />
      <Prep />
    </div>
  );
};

export default Home;
