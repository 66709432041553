import React, { useState } from "react";
import * as classes from "./Testimonials.module.css";
// react-animate-on-scroll
import ScrollAnimation from "react-animate-on-scroll";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";

const Testimonials = () => {
  const slides = [
    {
      text: `"Thank you Richard for coming to our middle school and doing an amazing clinic with so many of our young saxophonists. The students were excited about the music you had them play and much more motivated to work on their technique after the clinic ended. Our Advanced Band saxophone students are planning to play a couple of the pieces you brought to them at our Spring concert this May. Your time and energy with them was truly appreciated!"`,
      name: "Leanne Rzepiela, Crittenden Middle School Music Director"
    },
    {
      text: `"An accomplished sax player with extensive experience.  Richard is an able, patient teacher.  A great investment for anyone starting out, or looking to improve!"`,
      name: "Bill Duke"
    },
    {
      text: `“Lessons from Richard are educational, engaging and fun. I have been
      taking tenor sax lessons from Richard for over a year. He has helped
      inspire me to commit to my instrument more than I ever have over twenty
      years since I've owned and played a horn. His lessons are both reasonably
      priced and thoroughly enjoyable. I enjoy the ease of his teaching style, his
      knowledge and depth of music theory, and perhaps most of all, I appreciate
      his great sense of humor. I find his teaching style to strike the right balance
      of being encouraging and gently pushing me to grow as a player. The
      structure of the lessons are also very appealing. They are a blend of warm
      up exercises, theory, scales, and playing some songs. Almost immediately
      after beginning my lessons we began to work on tunes (in my case, jazz
      standards). Learning to sight read has been one of the most rewarding
      aspects of my education with Richard. I never thought I would read as well
      as I do and I have him to thank for it. I highly recommend him as a teacher
      whether you are completely new, intimidated by your horn or, like me, been
      sitting with your neglected sax for a number of years, but have always
      dreamed of making it sing again.”`,
      name: "Lear S. B. July 2020"
    },
    {
      text:`“Over the past couple of years, I have had the pleasure of performing with
      Rich as well as listening to him perform as a soloist. Rich brings an
      incredible amount of warmth and musicianship to any performing situation.
      As a teacher, he imparts these important skills on to his students.”`,
      name:"Fred Munic - July 2020"
    }
  ];

  const nextHandler = () => {
    if (index === slides.length - 1) {
      setIndex(0);
      return;
    }
    setIndex(index + 1);
  };

  const prevHandler = () => {
    if (index === 0) {
      setIndex(slides.length - 1);
      return;
    }
    setIndex(index - 1);
  };

  const [index, setIndex] = useState(0);
  return (
    <ScrollAnimation animateIn="fadeIn" duration={2}>
      <div className={classes.Testimonials}>
        <h3>See what others are saying</h3>
        <div className={classes.Slider}>
          <div className={classes.Slide}>
            <p className={classes.Text}>{slides[index].text}</p>
            <p className={classes.Name}>-{slides[index].name}</p>
          </div>
          <button className={classes.Prev} onClick={prevHandler}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button className={classes.Next} onClick={nextHandler}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default Testimonials;
