import React from "react";
import * as classes from "./Bio.module.css";
import bioPic from "../../assets/img/richard_avila_saxophone_instructor.jpg";

// react-animate-on-scroll
import ScrollAnimation from "react-animate-on-scroll";

import Button from "../../UI/Button/Button";

const Bio = () => {
  return (
    <ScrollAnimation animateIn="fadeIn" duration={2}>
      <div className={classes.Bio}>
        <div className={classes.About}>
          <h2>Richard Avila</h2>
          <h1>Saxophone Instructor</h1>
          <p>
            I began playing the saxophone in 8th grade. In high school I was 1st
            chair alto in Symphonic Band and Jazz Ensemble. While still in
            school, formed and played in a jazz quartet that won the Unanimous
            Superior award at the California State High School Music
            Competition.
            <br />
            <br />
            After high school, I played Lead Alto and 1st Tenor saxophone in the
            West Valley College Jazz Ensemble over a 4 year period.
            <br />
            <br />
            Outside of school I played flute, alto and soprano saxophones with
            the jazz, latin fusion band Zamar for twelve years (comprised of 7
            professional level musicians). Also was the leader of the music
            department for 3 different churches in the Santa Clara Valley over a
            12 year period.
          </p>
          <Button linkBtn link="/About">
            Learn More
          </Button>
        </div>
        <ScrollAnimation
          animateIn="fadeInRight"
          duration={1.5}
          animateOnce={true}
        >
          <img
            className={classes.Image}
            src={bioPic}
            alt="Richard Avila Saxophone Instructor | Second Winds Music"
          />
        </ScrollAnimation>
      </div>
    </ScrollAnimation>
  );
};

export default Bio;
