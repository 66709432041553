import React from "react";
import * as classes from "./Card.module.css";

const Card = props => {
  let image = props.img ? props.img : null;
  let cardClasses = [classes.Card];
  if (props.animated) {
    cardClasses = [classes.Card, classes.Animated];
  }
  return (
    <article className={cardClasses.join(" ")}>
      <div>
        {image ? (
          <img className={classes.Image} src={image} alt={props.title} />
        ) : null}
        <h4 className={classes.Title}>{props.title}</h4>
        <p className={classes.Content}>{props.children}</p>
      </div>
      {props.link ? (
        <a className={classes.Link} href={props.link}>
          {props.linkTitle}
        </a>
      ) : null}
    </article>
  );
};

export default Card;
