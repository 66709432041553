import React from "react";
import * as classes from "./SolidFoundation.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic } from "@fortawesome/free-solid-svg-icons";

import ScrollAnimation from "react-animate-on-scroll";

import musicalLove from "../../assets/img/saxophone_lessions.jpeg";

const SolidFoundation = () => {
  return (
    <ScrollAnimation animateIn="fadeIn">
      <div className={classes.SolidFoundation}>
        <h1>
          Solid Foundation <span>Teaching Methodology</span>
        </h1>
        <div className={classes.Container}>
          <p className={classes.Body}>
            Whether the student is a beginner or a more advanced player, musical
            skills and technique must be built on a{" "}
            <span className={classes.SolidTxt}>“solid foundation”</span> to
            achieve musical mastery in every technical aspect and at every
            playing level. A true beginner will first learn how to properly hold
            the instrument, how to breath from the diaphragm, develop good
            embouchure (correct mouth position to the mouthpiece and reed) and
            how to produce a good and controlled sound from the instrument. This
            will insure the beginning student will not become frustrated in the
            critical early stages of learning to play an instrument, especially
            while the student is still developing the belief that they can
            actually become a good musician. Students who are at various other
            stages of playing ability are assessed as to what areas need
            correction and improvement to create that{" "}
            <span className={classes.SolidTxt}>“solid foundation”</span>.<br />{" "}
            <br /> Each student will have their own lesson plan tailored to fit
            their needs and skill level. Just as a house that is built on a{" "}
            <span className={classes.SolidTxt}>“solid foundation”</span> will
            not develop sagging floors and cracking walls, so the student’s
            playing technique will not develop poor tone production, erratic
            cadence and other technical problems with the careful and proper
            development of the skills of saxophone playing.
          </p>
          <div className={classes.List}>
            <p>
              The <span className={classes.SolidTxt}>“solid foundation”</span>{" "}
              teaching methodology is built on the following:
            </p>
            <ul>
              <li>
                <FontAwesomeIcon className={classes.Icon} icon={faMusic} />{" "}
                Breathing development and technique
              </li>
              <li>
                <FontAwesomeIcon className={classes.Icon} icon={faMusic} />{" "}
                Embouchure development
              </li>
              <li>
                <FontAwesomeIcon className={classes.Icon} icon={faMusic} />{" "}
                Basic instrument playing knowledge
              </li>
              <li>
                <FontAwesomeIcon className={classes.Icon} icon={faMusic} />{" "}
                Preparation and warm up for practice, lessons and performance
              </li>
              <li>
                <FontAwesomeIcon className={classes.Icon} icon={faMusic} />{" "}
                Utilizing the most accepted and recommended musical study
                material
              </li>
              <li>
                <FontAwesomeIcon className={classes.Icon} icon={faMusic} />{" "}
                Development of smooth and even meter with use of a metronome
              </li>
              <li>
                <FontAwesomeIcon className={classes.Icon} icon={faMusic} />{" "}
                Progressive approach to increasing skill an musical ability
              </li>
              <li>
                <FontAwesomeIcon className={classes.Icon} icon={faMusic} />{" "}
                Specific focus on mastering each students unique technical
                challenges
              </li>
              <li>
                <FontAwesomeIcon className={classes.Icon} icon={faMusic} />{" "}
                Learning basic and relational music theory
              </li>
              <li>
                <FontAwesomeIcon className={classes.Icon} icon={faMusic} />{" "}
                Periodic measurement of students progress
              </li>
            </ul>
          </div>
          <img
            className={classes.Img}
            src={musicalLove}
            alt="Solid Foundation of the love of music"
          />
          <p className={classes.Conc}>
            This <span className={classes.SolidTxt}>“solid foundation”</span>{" "}
            approach will facilitate much swifter development of the students
            playing ability as well as increasing their enjoyment and love for
            their instrument and of music in general.
          </p>
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default SolidFoundation;
